import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
  path: '/admin_page',
  components: {
    default: () => import(/* webpackChunkName: "admin_page" */ '../components/admin_menu.vue')
  }
},
// {
//   path: '/search_modal',
//   components: {
//     default: () => import(/* webpackChunkName: "admin_page" */ '../components/data_search.vue')
//   }
// },
{
  path: '/',
  components: {
    default: () => import('../components/qr_read.vue')
  }
},
{
  path: '/about',
  name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import('../components/HelloWorld.vue')
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router