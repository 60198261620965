import { createStore } from 'vuex'

export default createStore({
    state: {
		modal: {
			isOpen: false,
			message: '',
		},
	},
	mutations: {
    // 追加
		commitModalOpen(state, payload) {
			state.modal = payload
		},
        commitModalClose(state, payload) {
			state.modal = payload
		},
	},
	actions: {
    // 追加
		actionModalOpen({ commit }, payload) {
			commit('commitModalOpen', payload)
		},
        actionModalClose({ commit }, payload) {
			commit('commitModalClose', payload)
		},
	},
	modules: {},
})