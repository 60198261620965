import { createApp } from 'vue'
import App from './App.vue'
import axios from "axios"
import router from './router'
import store from './store'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
// import UIkit from 'uikit'
// import firebase from "@firebase/util"


UIkit.use(Icons)
const vueObj = createApp(App)
vueObj.config.globalProperties.$axios = axios.create({
    baseURL: 'https://non-contact.dev-holonicsystem.com',
});


// vueObj.use(firebase)
vueObj.use(router)
vueObj.use(store)
vueObj.config.errorHandler = function (err, vm, info) {
    console.log('catched by `Vue.config.errorHandler`', err.toString());
    console.log(err, vm, info);
}
window.addEventListener("error", event => {
    console.log("Captured in error EventListener", event.error);
});
window.addEventListener("unhandledrejection", event => {
    console.log("Captured in unhandledrejection EventListener", event.reason);
});

vueObj.mount('#app')

